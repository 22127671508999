/*global google*/
import { parseAddressObject, getFormattedAddress } from "@/utils/helpers"

const address = {
  namespaced: true,
  state: {
    address: '',
    zip: '',
    state: '',
    city: '',
    county: '',
    error: false,
    completedGoogleMapsAutoComplete: false
  },
  mutations: {
    SET_ADDRESS_CHANGE_ERROR(state) {
      state.error = !state.completedGoogleMapsAutoComplete
    },
    SET_ADDRESS(state, address) {
      state.address = address
    },
    SET_ADDRESS_RELATED_DATA(state, parsedAddress) {
      const { state_2letters, city, zip, county } = parsedAddress
      state.state = state_2letters
      state.city = city
      state.zip = zip
      state.county = county
    },
    SET_VALIDATION(state, {
      completedGoogleMapsAutoComplete,
      error
    }) {
      state.completedGoogleMapsAutoComplete = completedGoogleMapsAutoComplete
      state.error = error
    },
    RESET_ADDRESS(state) {
      state.address = ''
      state.zip = ''
      state.state = ''
      state.city = ''
      state.county = ''
      state.error = true
      state.completedGoogleMapsAutoComplete = false
    }
  },
  actions: {
    initGoogleMapsAutocomplete(context, addressInput) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        fields: ["address_components", "geometry"],
        types: ["address"],
        componentRestrictions: {country: 'us'}
      })

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return
        }
        const formattedAddress = getFormattedAddress(place)
        const event = new CustomEvent('get_address_info', {
          detail: {
            formattedAddress,
            parsedAddress: parseAddressObject(place.address_components)
          }
        })
        dispatchEvent(event)
      })
    },
    getAddressInfo({state, commit}) {
      addEventListener('get_address_info', (addressInfo) => {
        if (addressInfo.detail.formattedAddress) {
          //update address in state
          commit('SET_ADDRESS', addressInfo.detail.formattedAddress)

          //set state, city, zip, and county
          commit('SET_ADDRESS_RELATED_DATA', addressInfo.detail.parsedAddress)

          //for validations
          commit('SET_VALIDATION', {
            completedGoogleMapsAutoComplete: true,
            error: false
          })

          //set fields in index
          const fields = [
            {field: 'address', value: state.address},
            {field: 'zip', value: state.zip},
            {field: 'city', value: state.city},
            {field: 'state', value: state.state},
            {field: 'county', value: state.county}
          ]

          commit('SET_FIELDS', fields, {root: true})
          commit('SET_VALIDATION', {validation: 'address', value: !state.error}, {root: true})
        }
      })
    }
  }
}

export default address