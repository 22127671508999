export const parseAddressObject = (address_components) => {
  const ShouldBeComponent = {
    home: ["street_number"],
    addressZipCode: ["postal_code"],
    street: ["street_address", "route"],
    state: [
      "administrative_area_level_1",
    ],
    county: [
      "administrative_area_level_2",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4"
    ],
    zip: [
      'postal_code'
    ]
  };

  const address = {
    home: "",
    addressZipCode: "",
    street: "",
    state: "",
    state_2letters: "",
    county: "",
    city: "",
    zip: ""
  }

  if (address_components !== undefined) {
    address_components.forEach(component => {
      for (let shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "state") {
            address[shouldBe] = component.long_name;
            address.state_2letters = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    })
  }
  return address;
}

export const getFormattedAddress = (place) => {
  const addressNameFormat = {
    'street_number': 'short_name',
    'route': 'long_name',
    'locality': 'long_name',
    'administrative_area_level_1': 'short_name',
    'country': 'short_name',
    'postal_code': 'short_name',
  }

  const getAddressComp = (type) => {
    for (const component of place.address_components) {
      if (component.types[0] === type) {
        return component[addressNameFormat[type]]
      }
    }
    return ''
  }

  return `${getAddressComp('street_number')} ${getAddressComp('route')}, ${getAddressComp('locality')}, ${getAddressComp('administrative_area_level_1')}`
}

export const setYearsRange = (yearMin) => {
  const currentYear = new Date().getFullYear()
  return Array(currentYear - yearMin + 1).fill().map((_, idx) => currentYear - idx)
}

export const extractUrlParams = () => {
  const urlSearchParams = new URLSearchParams(location.search)

  const obj = {}

  urlSearchParams.forEach((value, key) => {
    switch(key) {
      case 's1':
        obj['sub_id'] = checkForPlaceholder(value) || '1'
        break
      case 'sub2':
        obj['sub_id2'] = checkForPlaceholder(value)
        break
      case 'sub3':
        obj['sub_id3'] = checkForPlaceholder(value)
        break
      case 'sub4':
        obj['sub_id4'] = checkForPlaceholder(value)
        break
      default:
        obj[key] = checkForPlaceholder(value)
    }
  })

  if ('sub1' in obj) {
    obj['sub_id'] = checkForPlaceholder(obj['sub1']) || '1'
  }

  if ('sub5' in obj) {
    obj['click_id'] = checkForPlaceholder(obj['sub5']) || ''
    delete obj.sub5
  }

  return obj;
}

export const checkForPlaceholder = text => /^{.+}$/g.test(text) ? '' : text

export const trustedFormPromise = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const trustedForm = getTrustedForm()
      if (trustedForm.trustedFormUrl.length > 0) {
        clearInterval(interval)
        resolve(trustedForm)
      }
    }, 5)
  })
}

export const getTrustedForm = () => {
  let trustedFormUrl = '';
  let trustedFormId = '';
  if (document.getElementById('xxTrustedFormCertUrl_0')) {
    const tfCertUrl = document.getElementById('xxTrustedFormCertUrl_0').value
    const tfParts = tfCertUrl.split("https://cert.trustedform.com/");
    trustedFormUrl = tfCertUrl;
    trustedFormId = tfParts[1];
  }
  return {
    trustedFormUrl,
    trustedFormId
  }
}

export const getIpAddress = async () => {
  let ip = ''

  try {
    const r = await fetch("https://api.ipify.org?format=json")
    const response = await r.json()
    ip = response.ip
  } catch(e) {
    const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    const response = await r.text()
    const ipArray = response.match(/ip=([^\n]+)/)
    if (ipArray && ipArray.length > 1) {
      ip = ipArray[1]
    }
  }
  return ip
}

export const setEverFlow = () => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const EF = window.EF
      if (EF) {
        const urlParams = new URLSearchParams(window.location.search)
        EF.click({
          offer_id: EF.urlParameter('oid') || 7,
          affiliate_id: EF.urlParameter('affid') || 1,
          sub1: EF.urlParameter('sub1'),
          sub2: EF.urlParameter('sub2'),
          sub3: EF.urlParameter('sub3'),
          sub4: EF.urlParameter('sub4'),
          sub5: EF.urlParameter('sub5'),
          uid: EF.urlParameter('uid'),
          source_id: EF.urlParameter('source_id'),
          transaction_id: EF.urlParameter('_ef_transaction_id'),
        }).then(tid => {
          if (tid) {
            urlParams.set('ef_transaction_id', tid)
            history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
            resolve({ ef_transaction_id : tid })
          }
        })
        clearInterval(interval)
      }
    },5)
  })
}