const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const email = {
  namespaced: true,
  state: {
    value: '',
    error: false
  },
  getters: {
    passedRegex: state => VALID_EMAIL.test(String(state.value).toLowerCase())
  },
  mutations: {
    SET_EMAIL(state, email) {
      state.value = email
    },
    SET_EMAIL_ERROR(state, error) {
      state.error = error
    }
  }
}


export default email