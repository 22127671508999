const names = {
  namespaced: true,
  state: {
    firstName: {
      value: '',
      error: false
    },
    lastName: {
      value: '',
      error: false
    }
  },

  mutations: {
    SET_NAME(state, {name, nameValue}) {
      //set name changed
      state[name].inputChange = nameValue.length !== 0

      //set name error
      state[name].error = nameValue.length === 0

      //set name value
      state[name].value = nameValue
    }
  }
}

export default names