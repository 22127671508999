<script>
export default {
  name: "Footer"
}
</script>

<template>
  <div class="footer">
    <div class="footer-container">
      <div class="first-content">
        <div class="wrapper">
          <img src="../../assets/logo_files/svg/color_logo-no_background.svg" alt="">

          <div class="footer-nav">
            <router-link class="nav-button" title="Privacy Policy" to="/privacy-policy" target="_blank">Privacy Policy</router-link>
            <router-link class="nav-button" title="Terms of Use" to="/terms-of-use" target="_blank">Terms of Use</router-link>
          </div>
        </div>
      </div>

      <div class="second-content">
        <div class="text">
          Copyright © 2022 Zen Auto Insurance | All rights reserved.
        </div>
      </div>

    </div>
  </div>
</template>