<script>
import Header from "@/components/shared/Header"
import Footer from "@/components/shared/Footer"
export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<template>
  <div id="root-content">
    <Header/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<style>
</style>
