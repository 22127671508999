const phone = {
  namespaced: true,
  state: {
    value: '',
    error: false,
  },
  mutations: {
    SET_PHONE(state, phone) {
      state.value = phone
      state.error = state.value.length < 14
    },
    SET_PHONE_ERROR(state, error) {
      state.error = error
    }
  }
}

export default phone