import axios from 'axios'
import { createStore } from 'vuex'
import { extractUrlParams, getIpAddress } from "@/utils/helpers"

import names from "@/store/names"
import email from "@/store/email"
import phone from "@/store/phone"
import date_of_birth from "@/store/date_of_birth"
import address from "@/store/address"
import vehicle from "@/store/vehicle"

export default createStore({
  state: {
    fields: {
      lp_campaign_id: 5697,
      lp_supplier_id: 10900,
      lp_key: 'dkbm3msp1k'
    },
    loading: false,
    attemptSubmit: false,
    validated: {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      date_of_birth: false,
      address: false,
      vehicle: false
    },
  },
  getters: {
    allValidated: state => Object.entries(state.validated).every(value => value[1]),
    getUrlParameters: () => extractUrlParams()
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },

    SET_FIELD(state, {field, value}) {
      state.fields[field] = value
    },

    SET_FIELDS(state, fields) {
      fields.forEach(({ field, value }) => {
        state.fields[field] = value
      })
    },

    SET_URL_PARAMETERS(state, fields) {
      for (const field in fields) {
        state.fields[field] = fields[field]
      }
    },

    UNSET_FIELD(state, field) {
      delete state.fields[field]
    },

    UNSET_FIELDS(state, fields) {
      fields.forEach(field => delete state.fields[field])
    },

    SET_VALIDATION(state, {validation, value}) {
      state.validated[validation] = value
    },

    SET_DEFAULT_CAMPAIGN(state, {lp_campaign_id, lp_supplier_id, lp_key}) {
      state.fields.lp_campaign_id = lp_campaign_id
      state.fields.lp_supplier_id = lp_supplier_id
      state.fields.lp_key = lp_key
    }
  },
  actions: {
    init({commit, getters}) {
      //first set all url params using the getter
      commit('SET_URL_PARAMETERS', getters.getUrlParameters)

      //lp_campaign_id, lp_supplier_id, and lp_key MUST all be present in the URL to not be set to default
      if (!('lp_campaign_id' in getters.getUrlParameters && 'lp_supplier_id' in getters.getUrlParameters && 'lp_key' in getters.getUrlParameters)) {
        commit('SET_DEFAULT_CAMPAIGN', {
          lp_campaign_id: 5697,
          lp_supplier_id: 10900,
          lp_key: 'dkbm3msp1k'
        })
      }

      // get ip
      getIpAddress().then((ip) => {
        commit('SET_FIELD', { field: 'ip', value: ip })
      })
    },
    async sendDataToLp({state, dispatch, commit}) {
      const fields = state.fields
      const url = 'https://api.leadprosper.io/ingest'

      axios
        .post(url, fields)
        .then(() => {
          //go to thank you page
          dispatch('goToThankYouPage')
        })
      commit('SET_LOADING', false)
    },
    goToThankYouPage() {
      window.location.href = '/thank-you'
    }
  },
  modules: {
    names,
    email,
    phone,
    date_of_birth,
    address,
    vehicle
  }
})
