import axios from 'axios'

const vehicle = {
  namespaced: true,
  state: {
    carYear: '',
    carMake: '',
    carModel: '',
    carYearSelected: false,
    carMakeSelected: false,
    carModelSelected: false,
    rawCars: []
  },
  mutations: {
    SET_CAR_YEAR(state, {year, selected}) {
      state.carYear = year
      state.carYearSelected = selected
    },
    SET_ALL_CARS(state, cars) {
      state.rawCars = cars
    },
    SET_CAR_MAKE(state, {make, selected}) {
      state.carMakeSelected = selected
      state.carMake = make
    },
    SET_CAR_MODEL(state, {model, selected}) {
      state.carModelSelected = selected
      state.carModel = model
    },
  },
  getters: {
    getCarMakes: state => {
      const makes = state.rawCars.map(car=> car.make)
      return new Set(makes)
    },
    getCarModels: state => state.rawCars.filter(car => car.make === state.carMake),
    getCarValidated: state => state.carYearSelected && state.carMakeSelected && state.carModelSelected
  },
  actions: {
    async loadCarData({state, commit}) {
      await axios
        .get(`../car-data/${state.carYear}.json`)
        .then(response => {
          commit('SET_ALL_CARS', response.data)
        })
    }
  }
}

export default vehicle