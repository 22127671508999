<script>
export default {
  name: "Header"
}
</script>

<template>
  <div class="nav">
    <div class="nav-container">
      <div class="box">
        <router-link to="/">
          <img src="../../assets/logo_files/svg/white_logo-no_background.svg" alt="" class="logo">
        </router-link>
      </div>
    </div>
  </div>
</template>