import isValid from 'date-fns/isValid'
import format from 'date-fns/format'

const date_of_birth = {
  namespaced: true,
  state: {
    value: '',
    error: false,
    changedInput: false
  },
  mutations: {
    SET_DATE_OF_BIRTH(state, dob) {
      state.value = format(new Date(dob), 'yyyy-MM-dd')
      state.error = !isValid(dob)
    },
    SET_CHANGED_INPUT(state, status) {
      state.changedInput = status
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  actions: {
    SET_COMPLETED_DOB({commit, state}) {
      commit('SET_FIELD', {field: 'dob', value: state.value}, {root: true})
    }
  }
}

export default date_of_birth