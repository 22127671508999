import { createWebHistory, createRouter } from "vue-router"
const AutoInsuranceForm = () => import(/* webpackChunkName: "AutoInsuranceForm" */ "@/components/AutoInsuranceForm")
const PrivacyPolicy = () => import (/* webpackChunkName: "PrivacyPolicy" */ "@/components/shared/PrivacyPolicy")
const TermsOfUse = () => import (/* webpackChunkName: "TermsOfUse" */ "@/components/shared/TermsOfUse")
const Partners = () => import (/* webpackChunkName: "Partners" */ "@/components/shared/Partners")
const ThankYou = () => import (/* webpackChunkName: "ThankYou" */ "@/components/shared/ThankYou")

//TODO: Set up meta titles for webpage

const routes = [
  {
    path: "/",
    name: "Zen Auto Insurance",
    component: AutoInsuranceForm,
    meta: { title: 'Zen Auto' }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy'}
  },
  {
    path: '/terms-of-use',
    name: 'Terms of Use',
    component: TermsOfUse,
    meta: { title: 'Terms of Use' }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
    meta: { title: 'Partners' }
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: ThankYou,
    meta: { title: 'Thank You' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;